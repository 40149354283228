<template>
  <div class="page-dealer-index-af bg-main-af">
    <fragment v-if="saleMenu.length">
    <h3 class="title">
      <strong>销售流程检核</strong>
    </h3>
    <div class="box-item">
      <p v-for="(item, idx) in saleMenu" @click="jumpTo(item)">
        <i :class="item.icon"></i>
        <span>{{item.title}}</span>
      </p>
    </div>
    </fragment>

    <fragment v-if="afMenu.length">
    <h3 class="title">
      <strong>售后服务检核</strong>
    </h3>
    <div class="box-item">
      <p v-for="(item, idx) in afMenu" @click="jumpTo(item)">
        <i :class="item.icon"></i>
        <span>{{item.title}}</span>
      </p>
    </div>
    </fragment>

    <NavBar tab="首页" :both="true"></NavBar>
  </div>
</template>

<script>
import Vue from 'vue'
import { postForm, fetchGet } from '@/api'
import NavBar from '@/components/afNavbarDealer.vue';


export default {
  data() {
      return {
        list: [
          {
            title: '统计分析',
            link: 'dealerIndex',
            icon: 's1',
          },
          {
            title: '检核明细',
            link: 'checkDetailList',
            icon: 's2',
          },
          {
            title: '试驾明细',
            link: 'driveList',
            icon: 's3',
          },
        ],
        afList: [
          {
            title: '售后分析',
            link: 'afDealerAnalysis',
            icon: 'af2',
          },
          {
            title: '明细列表',
            link: 'afCheckDetailList',
            icon: 'af1',
          },
        ],
      }
  },
  computed: {
    saleMenu () {
      const userInfo = Vue.ls.get('USER_INFO') || {};
      const l = userInfo.gzh_sale_menu_arr || [];
      const m = this.list.filter(o => (l.indexOf(o.link) >= 0));
      return [...m];
    },
    afMenu () {
      const userInfo = Vue.ls.get('USER_INFO') || {};
      const l = userInfo.gzh_sa_menu_arr || [];
      const m = this.afList.filter(o => (l.indexOf(o.link) >= 0));
      return [...m];
    },
  },
  components:{
    NavBar,
  },
  methods : {
    jumpTo(item) {
      this.$router.push({name: item.link})
    },
  },
  created() {
    const {id} = this.$router.currentRoute.query || {};
  },
  mounted () {
  },
}
</script>

<style lang="less">

.page-dealer-index-af {
  padding-top: 0.5rem;

  h3.title {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    font-size: 0.8rem;
    margin-top: 2.5rem;
    margin-bottom: 0.5rem;
    display: flex;

    >span {
      flex: 1;
      text-align: right;
      .el-radio__input {
        width: 20px;
      }
      .el-radio__label {
        padding-left: 0;
        color: #000;
      }
    }
  }

  .box-item {
    display: flex;
    p {
      width: 30%;
      text-align: center;
      margin-top: 0.5rem;

      span {
        display: block;
        font-size: 0.7rem;
      }
      i {
        display: inline-block;
        width: 1.6rem;
        height: 1.6rem;

        &.s1 {
          background: url('~@/assets/m_s_1.png') center center no-repeat;
          background-size: cover;
        }
        &.s2 {
          background: url('~@/assets/m_s_2.png') center center no-repeat;
          background-size: cover;
        }
        &.s3 {
          background: url('~@/assets/m_s_3.png') center center no-repeat;
          background-size: cover;
        }
        &.af1 {
          background: url('~@/assets/m_af_1.png') center center no-repeat;
          background-size: cover;
        }
        &.af2 {
          background: url('~@/assets/m_af_2.png') center center no-repeat;
          background-size: cover;
        }
      }
    }
  }

}
</style>
